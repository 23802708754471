.intro {
	pointer-events: none;
	position: fixed;
	z-index:3;
	left:0;
	top:0;
	width: 100%;
	height: 100vh;
	background-color: #000000;
	transition: 1s;
}
.logo-header {
	position: absolute;
	top: 45%;
	left: 40%;
	transform: translate(-50%, -50%);
	color: #ffffff;
}

.logo {
	position: relative;
	display: inline-block;
	bottom: -20px;
	opacity: 0;
	font-size: 10rem;
}

span {
	padding:0.3rem;
}

.logo.active {
	bottom: 0;
	opacity: 1;
	transition: ease-in-out 2s; 
}

.logo.fade {
	bottom: 150px;
	opacity: 0;
	transition: ease-in-out 1s;
}

.intro.fade {
	opacity: 0;
	transition: ease-out 3s;
}

@media all and (max-width:680px) {
	span {
		padding:0 0;
		margin:0 0;
	}
	.logo {
		font-size: 3rem;
	}
}